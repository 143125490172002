import { ReturnApi } from './../model/Common.model';
import { PoImportSetting, PIList, POItems, StorageLocation, FileUpload, PurGroupSearch, PurGroup, POApproveFlow } from './../model/Purchaseorder.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { DROPDOWNLIST } from '../model/Purchaseorder.model';
import { POApprover } from '../model/setting.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  })
};

const httpOptionsSap = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
    Authorization: 'Basic ' + btoa('PICBMD03:Picbmd-03'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  })
};
let BASEURL = '';
@Injectable()
export class PIService {
  private Url = ''; // Url to web api
  currentUser: any;
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;
  }
  getpoapproveflowsupmit(data): Observable<Array<POApproveFlow>> {
    return this.http.post<Array<POApproveFlow>>(BASEURL + 'api/pi/getpoapproveflowsupmit/', data);
  }

  UpdatePrCpoApprover(Data: any) {
    return this.http.post<any>(BASEURL + 'api/pi/UpdatePrCpoApprover', Data);
  }
  getapprovepositionlist(POHeaderID: number, CpoPosition: string) {
    return this.http.post<any>(BASEURL + 'api/pi/getapprovepositionlist', { rpoheaderid: POHeaderID, rposition: CpoPosition });
  }
  Getpolist(value: any): Observable<any> {
    // const as = Object.assign({ pstatus: 0 });
    return this.http.post<any>(BASEURL + 'api/pi/Getpolist', value);
  }
  GetDLL_PILIST(): Observable<any> {
    return this.http.get<DROPDOWNLIST[]>(BASEURL + 'api/pi/GetDLL_PILIST');
  }
  SavePIApproveSetting(list: any): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/SavePIApproveSetting', list, httpOptionsSap);
  }
  MutiUpdateStatusPI(list): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/MutiUpdateStatusPI', list, httpOptionsSap);
  }
  GetPoImportList(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/Poimport');
  }
  GetPOApproverListByProductTypeid(producttypeid): Observable<Array<POApprover>> {
    return this.http.get<Array<POApprover>>(BASEURL + 'api/pi' + '/getpoapproverlistbyproducttypeid/'
      + producttypeid, httpOptions).pipe(tap(response => { }));
  }
  SavePoImport(header: PoImportSetting[]): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi' + '/SaveData', header, httpOptions).pipe(tap((response) => { }));
  }
  GetPOViewByID(value): Observable<ReturnApi> {
    return this.http.get<ReturnApi>(BASEURL + 'api/pi' + '/GetPOViewByID/' + value, httpOptions).pipe(tap((response) => { }));
  }
  AddpobyMerge(value: any) {
    return this.http.post<any>(BASEURL + 'api/pi' + '/AddpobyMerge', value, httpOptions);
  }

  GetDLL_PILIST_Manage(): Observable<any> {
    return this.http.get<DROPDOWNLIST[]>(BASEURL + 'api/pi/DLL_PILIST_Manage');
  }

  getValueDll(value: string): Observable<any> {
    return this.http.get<DROPDOWNLIST[]>(BASEURL + 'api/pi/Getamgtype/' + value);
  }

  getPoproductlistinforecord(value: any): Observable<any> {
    return this.http.post<POItems[]>(BASEURL + 'api/pi/getPoproductlistinforecord/', value);
  }

  Getpostoragelocation(value: number): Observable<any> {
    return this.http.get<StorageLocation[]>(BASEURL + 'api/pi/Getpostoragelocation/' + value);
  }

  PIManage(value: any): Observable<any> {
    console.log('value Send API', value);
    return this.http.post<any[]>(BASEURL + 'api/pi/PIManage', value);
  }

  GetCompanayProfile(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetCompanayProfile');
  }

  Getpoaddresses(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/Getpoaddresses', value);
  }

  Addandupdatepoaddresses(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/Addandupdatepoaddresses', value);
  }

  CancelPI(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/CancelPI', value);
  }
  DeletePI(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/DeletePI', value);
  }

  GetApproveList(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/GetApproveList', value);
  }

  GetUntiByMatVen(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/GetUntiByMatVen', value);
  }

  GetPRDoctypeDLL(value: any): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetPRDoctypeDLL/' + value);
  }

  getLoghistory(value: any): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetLoghistory/' + value);
  }

  Uploadfile(FormDatas: any) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };
    const options = { content: 'formData' };
    return this.http.post<any>(BASEURL + 'api/pi/Upload', FormDatas, httpOptions);
  }

  Downloadfile(value: any): Observable<Blob> {
    return this.http.post(BASEURL + 'api/pi/Download', value, { headers: httpOptions.headers, responseType: 'blob' });
  }

  UpdateAttachment(value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/pi/UpdateAttachment', value);
  }

  Getpobyid(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/Getpobyid/' + id);
  }

  GetPOApprovebyid(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/POApprovebyid/' + id);
  }

  GetPOViewByid(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPoView/' + id);
  }
  GetPurGroup(SearchParam?: PurGroupSearch): Observable<any> {
    // console.log("SearchParam",SearchParam);
    return this.http.post<any[]>(BASEURL + 'api/pi/PurGroup/', SearchParam);
  }
  ManageDataPurGroup(Data: PurGroup[]): Observable<any> { //Save Edit Delete
    console.log("Data", Data);

    return this.http.post<any[]>(BASEURL + 'api/pi/ManagePurGroup/', Data);
  }
  GetPIAppovalSettingList(DeptCode: string): Observable<any> {
    console.log("DeptCode", DeptCode);
    return this.http.get<any[]>(BASEURL + 'api/pi/PIAppovalSettingList/' + DeptCode);
  }

  SavePIApproveSetting1(list: any): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/SavePIApproveSetting1', list, httpOptionsSap);
  }

  GetPOReport(poid: number, currentUser: number): Observable<any> {
    return this.http.post(BASEURL + 'api/pi/GetPOReport', { ppoheaderid: poid, pprintby: currentUser }, { responseType: 'blob' })
  }
}
