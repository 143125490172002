import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '../../../node_modules/@angular/common/http';
import { SelectItem } from '../model/common.model';
import { Observable } from '../../../node_modules/rxjs';
import { tap, catchError } from '../../../node_modules/rxjs/operators';
import { WarehouseSearch, SelectWarehouseLocationList, GetLocationListByWHResult, GetShelfListByLCResult, Warehouse, Locations, Shelf, StationType } from '../model/warehouse.model';
import { of } from 'rxjs/observable/of';


const API_URL = environment.R3_GI_Reverse;
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

@Injectable()
export class WarehouseService {
  private companyID: string = "1";
  private Url = API_URL + '/' + this.companyID + '/warehouse';  // URL to web api
  private currentUser: any;

  constructor(
    private http: HttpClient) {
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.currentUser);
  }
  getStationType(): Observable<StationType[]> {
    return this.http.get<StationType[]>(this.Url+ '/GetStationType', httpOptions)
        .pipe(
            tap(response => {
                // this.log(`fetched `)
            })
        );
}
  GetWarehouseListForDisplay(): Observable<Warehouse[]> {
    return this.http.get<Warehouse[]>(this.Url + '/GetWarehouseListForDisplay')
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  } 

  GetWarehouseForExport(type: number): Observable<any> {

    return this.http.post(this.Url + '/GetWarehouseForExport/' + type, null, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',

        }),
        responseType: 'arraybuffer'
    }).pipe(tap((x) => {
    }),
        catchError(this.handleError<{}>('SearchWorkPermit'))
    );
} 

GetWarehouseByID(id: number): Observable<Warehouse> {
  return this.http.get<Warehouse>(this.Url + '/GetWarehouseListForDisplay' + '/' + id.toString(), httpOptions)
      .pipe(
          tap(response => {
              // this.log(`fetched `)
          })
      );
}

GetLocationByID(id: number): Observable<Locations[]> {
  return this.http.get<Locations[]>(this.Url + '/Location' + '/' + id.toString(), httpOptions)
      .pipe(
          tap(response => {
              // this.log(`fetched `)
          })
      );
}
  getWarehouseList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.Url, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  } 

  SaveWarehouse(data: Warehouse): Observable<any> {
    return this.http.post<any>(this.Url, data, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  SaveLocation(data: Locations): Observable<any> {
    return this.http.post<any>(this.Url + "/Location/save", data, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `) 
        })
      );
  }

  SaveShelf(data: Shelf): Observable<any> {
    return this.http.post<any>(this.Url + "/Shelf/save", data, httpOptions)
        .pipe(
            tap(response => {
                // this.log(`fetched `) 
            })
        );
}



  getLocationListByWarehouseId(id: number): Observable<GetLocationListByWHResult[]> {
    return this.http.get<GetLocationListByWHResult[]>(this.Url + "/GetLocationListByWH/" + id.toString(), httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }


  getShelfListByLocationIdId(id: number): Observable<Shelf[]> {
    return this.http.get<Shelf[]>(this.Url + "/Shelf/" + id.toString(), httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      ); 
  }


  getWarehouseLocationList(search: WarehouseSearch): Observable<SelectWarehouseLocationList[]> {
    return this.http.post<SelectWarehouseLocationList[]>(this.Url + "/" + "LocationList", search, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  getWarehouseLocationsList(search: WarehouseSearch): Observable<Locations[]> {
    return this.http.post<Locations[]>(this.Url + "/" + "LocationList", search, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  getLocationList(): Observable<Locations[]> {
    return this.http.post<Locations[]>(this.Url + '/Location', httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  } 

  GetLocationForExport(type: number): Observable<any> {

    return this.http.post(this.Url + '/GetLocationForExport/' + type, null, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',

        }),
        responseType: 'arraybuffer'
    }).pipe(tap((x) => {
    }),
        catchError(this.handleError<{}>('SearchWorkPermit'))
    );
} 

  getShelfBarcodePDF(data: number): Observable<any> {
    return this.http.get(this.Url + "/GetShelfBarcodePDF/" + data.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'arraybuffer'
    }).pipe(tap((x) => {

    }),
    );
  }


  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${error.message}`);

          // Let the app keep running by returning an empty result.
          return of(result as T);
      };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private log(message: string) {
      console.log(message);
      // this.messageService.add('ScgCompanyService: ' + message);
  }
}
