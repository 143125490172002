
import { AddressSearch, CommonSearch, ForwarderListResult, ShipmentTypeListResult, ShipmentClassifyListResult, TruckTypeResult } from './../model/common.model';
import { Vendor } from '../model/vendor.model';
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { SelectItem, Country, Attachment, PaymentTerm } from '../model/common.model';
import { User, UserModels } from '../model/user.model';
import { CustomerAddress } from '../model/customer.model';
import { SymbolModel, Item, JigList4Dropdown, CavityList4Dropdown, ToolingList4Dropdown } from '../model/item.model';
import { WarehouseList4Dropdown, LocationList4Dropdown } from '../model/warehouse.model';
import { TemplateAttrDetail, TemplateAttrDetailSearch, Brand, ItemSubClass, Status, SystemConfig, Department, Supplier, SystemAttachDoc, ItemCategory, ItemSubCategory, Supplier_Search, ProductType, POApprover, POApproverLevel, UserByPermission } from '../model/setting.model';
import { Status_old } from '../model/Status';


const API_URL = environment.R3_GI_Reverse;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
        }
    )
};
let BASEURL = '';
@Injectable()
export class SettingService {
  
  
  
  
    private Url = '';  // URL to web api
    currentUser: User;

    constructor(
        private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.Url = API_URL + '/' + ( "1") + '/settings';
        BASEURL = baseUrl;
    }
    getcountpendingapprovecontractagm(id: number):Observable<number> {
        return this.http.get<number>(BASEURL + 'api/setting/getcountpendingapprovecontractagm/'+id,  httpOptions).pipe(tap(response => { }));
       }
    getPOApproverLevel(): Observable<Array<POApproverLevel>> {
        return this.http.get<Array<POApproverLevel>>(BASEURL + 'api/setting' + '/getpoapproverlevel/', httpOptions).pipe(tap(response => { }));
  }
    
    GetProductTypeList(): Observable<Array<ProductType>> {
        return this.http.get<Array<ProductType>>(BASEURL + 'api/setting' + '/GetProductTypeList/', httpOptions).pipe(tap(response => { }));
  }
    GetItemCategoryList() : Observable<Array<ItemCategory>> {
        return this.http.get<Array<ItemCategory>>(BASEURL + 'api/setting' + '/GetItemCategoryList/', httpOptions).pipe(tap(response => { }));
    }
    GetItemSubCategoryList(CateCode) : Observable<Array<ItemSubCategory>> {
        return this.http.get<Array<ItemSubCategory>>(BASEURL + 'api/setting' + '/GetItemSubCategoryList/'+CateCode, httpOptions).pipe(tap(response => { }));
    }
    GetTemplateAttrDetail(search: TemplateAttrDetailSearch): Observable<Array<TemplateAttrDetail>> {
        return this.http.post<Array<TemplateAttrDetail>>(BASEURL + 'api/setting' + '/GetTemplateAttrDetail/', search, httpOptions).pipe(tap(response => { }));
    }
    GetItemSubClassList(): Observable<Array<ItemSubClass>> {
        return this.http.get<Array<ItemSubClass>>(BASEURL + 'api/setting' + '/GetItemSubClassList/', httpOptions).pipe(tap(response => { }));
    }
    GetBrandList(ItemSubClassCode): Observable<Array<Brand>> {
        return this.http.get<Array<Brand>>(BASEURL + 'api/setting' + '/GetBrandList/'+ItemSubClassCode, httpOptions).pipe(tap(response => { }));
    }
    GetStatus(transactionType): Observable<Array<Status>> { //contract
        return this.http.get<Array<Status>>(BASEURL + 'api/setting' + '/GetStatus/'+transactionType, httpOptions).pipe(tap(response => { }));
    }
    GetSystemConfig(ConfigCode): Observable<Array<SystemConfig>> {
        return this.http.get<Array<SystemConfig>>(BASEURL + 'api/setting' + '/GetSystemConfig/'+ConfigCode, httpOptions).pipe(tap(response => { }));
    }  
     GetSupplierList(SearchParam?: Supplier_Search): Observable<any> {
        return this.http.post<any>(BASEURL + 'api/setting' + '/getsupplierlist/', SearchParam, httpOptions).pipe(tap(response => { }));
    }
    getuserbypermission(search): Observable<Array<any>> {
        return this.http.post<Array<any>>(BASEURL + 'api/setting' + '/getuserbypermission/',search, httpOptions).pipe(tap(response => { }));
    }
    
    getLocationTypeList(): Observable<SelectItem[]> {
        return this.http.get<SelectItem[]>(this.Url + "/LocationType", httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }  
    GetApprovalList(): Observable<Array<UserModels>> {
        return this.http.get<Array<UserModels>>(BASEURL + 'api/setting' + '/GetApprovalList/', httpOptions).pipe(tap(response => { }));
    }
    GetApprovalListByParam(Param:UserByPermission): Observable<Array<UserModels>> {
        return this.http.post<Array<UserModels>>(BASEURL + 'api/setting' + '/GetApprovalListByOBj/',Param, httpOptions).pipe(tap(response => { }));
    }
    GetStatusList(transactionType): Observable<Array<Status>> {
        return this.http.get<Array<Status>>(BASEURL + 'api/setting' + '/GetStatus/'+transactionType, httpOptions).pipe(tap(response => { }));
    }
    GetDepartmentList(): Observable<Array<Department>> {
        return this.http.get<Array<Department>>(BASEURL + 'api/setting' + '/GetDepartmentList/', httpOptions).pipe(tap(response => { }));
    }
    GetUserList(deptID:string): Observable<Array<UserModels>> {
        return this.http.get<Array<UserModels>>(BASEURL + 'api/setting' + '/getuserbydeptid/'+deptID, httpOptions).pipe(tap(response => { }));
    }

    GetSystemAttachDocList(): Observable<Array<SystemAttachDoc>> {
        return this.http.get<Array<SystemAttachDoc>>(BASEURL + 'api/setting' + '/getsystemattachdoc/', httpOptions).pipe(tap(response => { }));
    }


//     GetCustomerAddressByType(data: AddressSearch): Observable<CustomerAddress[]> {
//         return this.http.post<CustomerAddress[]>(this.Url + "/GetCustomerAddressByType", data, httpOptions)
//             .pipe(
//                 tap(response => {
//                     // this.log(`fetched `)
//                 })
//             );
//     }


//     GetForwarderList(data: CommonSearch): Observable<ForwarderListResult[]> {
//         return this.http.post<ForwarderListResult[]>(this.Url + "/GetForwarderList", data, httpOptions)
//             .pipe(
//                 tap(response => {
//                     // this.log(`fetched `)
//                 })
//             );
//     }

//     GetShipmentTypeList(data: CommonSearch): Observable<ShipmentTypeListResult[]> {
//         return this.http.post<ShipmentTypeListResult[]>(this.Url + "/GetShipmentTypeList", data, httpOptions)
//             .pipe(
//                 tap(response => {
//                     // this.log(`fetched `)
//                 })
//             );
//     }

//     GetShipmentClassifyList(data: CommonSearch): Observable<ShipmentClassifyListResult[]> {
//         return this.http.post<ShipmentClassifyListResult[]>(this.Url + "/GetShipmentClassifyList", data, httpOptions)
//             .pipe(
//                 tap(response => {
//                     // this.log(`fetched `)
//                 })
//             );
//     }

//     GetTruckType(data: CommonSearch): Observable<TruckTypeResult[]> {
//         return this.http.post<TruckTypeResult[]>(this.Url + "/GetTruckType", data, httpOptions)
//             .pipe(
//                 tap(response => {
//                     // this.log(`fetched `)
//                 })
//             );
//     }



    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a ScgCompanyService message with the MessageService */
    private log(message: string) {
        console.log(message);
        // this.messageService.add('ScgCompanyService: ' + message);
    }


}
