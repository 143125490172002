import { Injectable } from '@angular/core';
import { Permission } from '../../permission/permission';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const Permission_TYPE = Permission;

const MENUITEMS = [
  //   {
  //     label: 'MASTER',
  //     main: [
  //       {
  //         state: 'list',
  //         short_label: 'I',
  //         main_state: 'iteminquiry',
  //         name: 'Part',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.ITEM_MASTER
  //       },
  //         {
  //         state: 'list',
  //         short_label: 'P',
  //         main_state: 'process',
  //         name: 'Process',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.ITEM_MASTER

  //       },
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'Ict',
  //       //   main_state: 'itemcategory',
  //       //   name: 'Item Category',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.ITEM_CATEGORY
  //       // },
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'bom',
  //       //   main_state: 'bom',
  //       //   name: 'Bill of Material',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.BOM

  //       // },

  //       {
  //         state: 'list',
  //         short_label: 'cus',
  //         main_state: 'customer',
  //         name: 'Customer',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.CUSTOMER

  //       },
  //       {
  //         state: 'list',
  //         short_label: 'sp',
  //         main_state: 'supplier',
  //         name: 'Supplier',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.SUPPLIER
  //       },
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'wh',
  //       //   main_state: 'warehouse',
  //       //   name: 'Warehouse',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.WAREHOUSE
  //       // },
  //       {
  //         state: 'list',
  //         short_label: 'lc',
  //         main_state: 'location',
  //         name: 'Location',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.LOCATION
  //       }
  //     ]
  //   },
  //   {
  //     label: 'MATERIAL',
  //     main: [
  //       {
  //         state: 'list',
  //         short_label: 'mrc',
  //         main_state: 'materialreceive',
  //         name: 'Material Receiving Plan',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.MATERIAL_RECEIVE
  //       },
  //       {
  //         state: 'matlist',
  //         short_label: 'mrc',
  //         main_state: 'materialreceive',
  //         name: 'Material Received',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.MATERIAL_RECEIVE
  //       },
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'irc',
  //       //   main_state: 'itemreceived',
  //       //   name: 'Item Receive',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.ITEM_RECEIVE
  //       // },
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'iqc',
  //       //   main_state: 'iqcinspection',
  //       //   name: 'IQC Inspection',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.IQC_INSPECTION
  //       // },
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'sqe',
  //       //   main_state: 'sqeinspection',
  //       //   name: 'SQE Inspection',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.SQE_INSPECTION
  //       // }
  //     ]
  //   },
  //   {
  //     label: 'PRODUCTION',
  //     main: [
  //       {
  //         state: 'lmslist',
  //         short_label: 'productionl',
  //         main_state: 'production',
  //         name: 'Production LMS',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.USER
  //       },
  //       {
  //         state: 'list',
  //         short_label: 'productionl',
  //         main_state: 'production',
  //         name: 'Production Result',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.USER
  //       },
  //     ]
  //   },
  //   {
  //     label: 'FINISH GOODS',
  //     main: [
  //       // {
  //       //   state: 'productionlist',
  //       //   short_label: 'fgPro',
  //       //   main_state: 'finishgoods',
  //       //   name: 'Production Result',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.PRODUCTION_RESULT
  //       // },
  //       {
  //         state: 'packingresultlist',
  //         short_label: 'fgPac',
  //         main_state: 'finishgoods',
  //         name: 'Packing Result',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.PACKING
  //       },
  //       // {
  //       //   state: 'packinglist',
  //       //   short_label: 'fgPac',
  //       //   main_state: 'finishgoods',
  //       //   name: 'Packing',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.PACKING
  //       // },
  //       // {
  //       //   state: 'genboxmanage',
  //       //   short_label: 'fgPro2',
  //       //   main_state: 'finishgoods',
  //       //   name: 'Packing Box',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.PRODUCTION_RESULT_P2
  //       // },


  //       {
  //         state: 'packingboxlist',
  //         short_label: 'fgPro2',
  //         main_state: 'finishgoods',
  //         name: 'Packing Box',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.PRODUCTION_RESULT_P2
  //       },
  //       {
  //         state: 'receivelist',
  //         short_label: 'fgPro2',
  //         main_state: 'finishgoods',
  //         name: 'FG Receive',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.PRODUCTION_RESULT_P2
  //       },
  //       // {
  //       //   state: 'semiproductionlist',
  //       //   short_label: 'fgPro2',
  //       //   main_state: 'finishgoods',
  //       //   name: 'Production Result P2',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.PRODUCTION_RESULT_P2
  //       // },

  //       // ,
  //       // {
  //       //   state: 'inspectionlist',
  //       //   short_label: 'fgIns',
  //       //   main_state: 'finishgoods',
  //       //   name: 'Inspection',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: Permission_TYPE.FG_INSPECTION
  //       // }
  //     ]
  //   },
  //   {
  //     label: 'SALE',
  //     main: [
  //       {
  //         state: 'importdo',
  //         short_label: 'fgPro',
  //         main_state: 'finishgoods',
  //         name: 'Import Delivery Order',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.PRODUCTION_RESULT
  //       },
  //       {
  //         state: 'list',
  //         short_label: 'shpin',
  //         main_state: 'shipping',
  //         name: 'Delivery Order List',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: Permission_TYPE.SHIPPING_INSTRUCTION
  //       }
  //     ]
  //   },

  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 't',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-bar-chart-alt',
      }
    ]
  },
  // {
  //   label: 'Main',
  //   main: [
  //     {
  //       state: 'contract-manage',
  //       name: 'รายการสัญญา',
  //       type: 'link',
  //       icon: 'ti-file',
  //     },


  //   ]
  // },
  {
    label: 'Contract',
    main: [
      {
        state: 'contract',
        short_label: 't',
        name: 'สัญญา',
        type: 'sub',
        icon: 'ti-book',

        children: [
          {
            state: 'contract-list',
            name: 'รายการสัญญา',
            type: 'link',
            icon: 'ti-book',
            p_type: Permission_TYPE.ContractList,
          },
          {
            state: 'agreement-list',
            name: 'รายการ Agreement',
            type: 'link',
            icon: 'ti-book',
            p_type: Permission_TYPE.AGMList,
          },
        ]
      },
      {
        state: 'approve',
        short_label: 't',
        name: 'อนุมัติสัญญา',
        type: 'sub',
        icon: 'ti-book',
        noti: true,
        children: [
          {
            state: 'contract-ap-list',
            name: 'รายการสัญญา',
            type: 'link',
            icon: 'ti-book',
            p_type: Permission_TYPE.ContractApproveList,
          },
          {
            state: 'agreement-ap-list',
            name: ' รายการ Agreement',
            type: 'link',
            icon: 'ti-book',
            p_type: Permission_TYPE.AGMApproveList,
          },
        ]
      },
    ],
  },
  // {
  //   label: '',
  //   main: [
  //     {
  //       state: 'approve',
  //       short_label: 't',
  //       name: 'อนุมัติสัญญา',
  //       type: 'sub',
  //       icon: 'ti-file',
  //       noti:true,
  //       children: [
  //         {
  //           state: 'contract-ap-list',
  //           name: 'รายการสัญญา',
  //           type: 'link',
  //           icon: 'ti-file',
  //           p_type: Permission_TYPE.ContractApproveList,
  //         },
  //         {
  //           state: 'agreement-ap-list',
  //           name: ' รายการ Agreement',
  //           type: 'link',
  //           icon: 'ti-file',
  //           p_type: Permission_TYPE.AGMApproveList,
  //         },


  //         // {
  //         //   state: 'agreement-tem',
  //         //   name: 'เทมเพลท Agreement',
  //         //   type: 'link',
  //         //   icon: 'ti-layout-grid2-alt',
  //         // },

  //         // {
  //         //   state: 'template-pi',
  //         //   name: 'เทมเพลท PI',
  //         //   type: 'link',
  //         //   icon: 'ti-layout-grid2-alt',
  //         // },

  //       ]
  //     },
  //   ],
  // },
  {
    label: 'GR/GI',
    main: [
      {
        state: 'goods-issue',
        name: 'การตัดจ่ายสินค้า',
        type: 'link',
        icon: 'ti-package',
        p_type: Permission_TYPE.GoodsIssue,
      },
      {
        state: 'edit-delivery',
        name: 'แก้ไขข้อมูล Delivery',
        type: 'link',
        icon: 'ti-package',
        p_type: Permission_TYPE.ChangDelivery,

      },
      {
        state: 'qty-goods-issue',
        name: 'แก้ไขข้อมูล Qty',
        type: 'link',
        icon: 'ti-package',
        p_type: Permission_TYPE.GoodsIssue,
      },
      {
        state: 'cancel-goods',
        name: 'ยกเลิกการตัดจ่ายสินค้า',
        type: 'link',
        icon: 'ti-package',
        p_type: Permission_TYPE.CancelGoodsIssue,
      },
      {
        state: 'return-goods',
        name: 'รับคืนสินค้า',
        type: 'link',
        icon: 'ti-package',
        p_type: Permission_TYPE.GoodsReturn,
      },
    ]
  },
  {
    label: 'Report',
    main: [
      {
        state: 'yy',
        external: '1',
        name: 'ReportName1',
        type: 'external',
        icon: 'ti-receipt',
        p_type: Permission_TYPE.Report1,
      },
      {
        state: 'xx',
        external: '2',
        name: 'ReportName2',
        type: 'external',
        icon: 'ti-receipt',
        p_type: Permission_TYPE.Report2,
      },
    ]
  },
  {
    label: 'Purchase Order',
    main: [

      {
        // main_state: 'purchaseorder',
        main_state: 'purchaseorder',
        state: 'pi-order-list',
        name: 'รายการสั่งซื้อ',
        type: 'link',
        icon: 'ti-receipt',
        p_type: Permission_TYPE.PIList,
      },
      {
        main_state: 'purchaseorder-approve',
        state: 'pi-approve-list',
        name: 'อนุมัติรายการสั่งซื้อ',
        type: 'link',
        icon: 'ti-receipt',
        p_type: Permission_TYPE.PIApproveList,
      },
    ]
  },
  {
    label: 'Master',
    main: [

      {
        state: 'supplier',
        name: 'ผู้ขาย',
        type: 'link',
        icon: 'ti-user',
        p_type: Permission_TYPE.Supplierlist,
      },
    ]
  },

  {
    label: 'Setting',
    main: [
      {
        state: 'template',
        short_label: 't',
        name: 'เทมเพลทสัญญา',
        type: 'sub',
        icon: 'ti-settings',

        children: [
          {
            state: 'contract-template-list',
            name: 'เทมเพลทสัญญา',
            type: 'link',
            icon: 'ti-settings',
            p_type: Permission_TYPE.ContractTemplateList,
          },
          {
            state: 'agreement-tem-list',
            name: 'เทมเพลท Agreement',
            type: 'link',
            icon: 'ti-settings',
            p_type: Permission_TYPE.AGMTemplateList,
          },

          // {
          //   state: 'template-pi',
          //   name: 'เทมเพลท PI',
          //   type: 'link',
          //   icon: 'ti-layout-grid2-alt',
          // },

        ]
      },
      {
        state: 'pi',
        short_label: 't',
        name: 'ตั้งค่าการอนุมัติ PI',
        type: 'sub',
        icon: 'ti-settings',
        p_type: (Permission_TYPE.PIApprovalSetting || Permission_TYPE.PIImportApprovalSetting),
        children: [
          {
            state: 'pi-approval-settting',
            name: 'ตั้งค่าอนุมัติตามจำนวนเงิน',
            type: 'link',
            icon: 'ti-settings',
            p_type: Permission_TYPE.PIApprovalSetting,
          },
          {
            state: 'pi-setting',
            name: 'ตั้งค่าผู้อนุมัติตำแหน่ง',
            type: 'link',
            icon: 'ti-settings',
            p_type: Permission_TYPE.PIApprovalSetting,
          },
          {
            state: 'pur-group',
            name: 'ตั้งค่า Pur Group',
            type: 'link',
            icon: 'ti-settings',
            p_type: Permission_TYPE.PIApprovalSetting,

          },
          {
            state: 'pi-import',
            name: 'ตั้งค่าผู้อนุมัติ PI Import',
            type: 'link',
            icon: 'ti-settings',
            p_type: Permission_TYPE.PIImportApprovalSetting,

          },
        ]
      }
    ],
  },
  // {
  //   label: 'Main',
  //   main: [
  //     {
  //       state: 'contract-manage',
  //       name: 'รายการสัญญา',
  //       type: 'link',
  //       icon: 'ti-file',
  //     },


  //   ]
  // },
  {
    label: 'USER & AUTHORIZATION',
    main: [
      // {
      //   state: 'list',
      //   short_label: 'userlh',
      //   main_state: 'grouppermission',
      //   name: 'Group-Permission',
      //   type: 'link',
      //   icon: 'ti-id-badge',
      //   p_type: Permission_TYPE.USER,
      // },
      // {
      //   state: 'list',
      //   short_label: 'userlh',
      //   main_state: 'user',
      //   name: 'User-Manage',
      //   type: 'link',
      //   icon: 'ti-id-badge',
      //   p_type: Permission_TYPE.PERMISSION,
      // },

      {
        state: 'list',
        short_label: 'userlh',
        main_state: 'grouppermission',
        name: 'Group-Permission',
        type: 'link',
        icon: 'ti-id-badge',
        p_type: Permission_TYPE.PERMISSION,
      },
      {
        state: 'list',
        short_label: 'userlh',
        main_state: 'user',
        name: 'User-Manage',
        type: 'link',
        icon: 'ti-id-badge',
        p_type: Permission_TYPE.USER,
      },


    ]
  },
  // {
  //   label: 'อนุมัติ PI',
  //   main: [
  //     {
  //       state: 'pur-group',
  //       name: 'ตั้งค่า Pur Group',
  //       type: 'link',
  //       icon: 'ti-layout-grid2-alt',
  //       // p_type: Permission_TYPE.PIImportApprovalSetting,

  //     },

  //   ],
  // },
  // {
  //   label: 'Example',
  //   main: [
  //     {
  //       state: 'preview-pdf',
  //       name: 'preview-pdf',
  //       type: 'link',
  //       icon: 'ti-file',
  //     },
  //     {
  //       state: 'text-editor',
  //       name: 'text-editor',
  //       type: 'link',
  //       icon: 'ti-file',
  //     },

  //     {
  //       state: 'drag-drop',
  //       name: 'drag-drop',
  //       type: 'link',
  //       icon: 'ti-file',
  //     },]
  // },

  //   {
  //     label: 'REPORT',
  //     main: [
  //       // {
  //       //   state: 'list',
  //       //   short_label: 'report',
  //       //   main_state: 'report',
  //       //   name: 'Report',
  //       //   type: 'link',
  //       //   icon: 'ti-layout-media-right',
  //       //   p_type: null
  //       // },
  //       {
  //         state: 'exportwinspeed',
  //         short_label: 'report',
  //         main_state: 'report',
  //         name: 'Export Winspeed',
  //         type: 'link',
  //         icon: 'ti-layout-media-right',
  //         p_type: null
  //       },
  //     ]
  //   }
  //   ,
  // {
  //   label: 'Navigation',
  //   main: [
  //     {
  //       state: 'dashboard',
  //       short_label: 'D',
  //       name: 'Dashboard',
  //       type: 'sub',
  //       icon: 'ti-home',
  //       children: [
  //         {
  //           state: 'default',
  //           name: 'Default'
  //         },
  //         {
  //           state: 'ecommerce',
  //           name: 'Ecommerce'
  //         },
  //         {
  //           state: 'analytics',
  //           name: 'Analytics',
  //           badge: [
  //             {
  //               type: 'info',
  //               value: 'NEW'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       state: 'navigation',
  //       short_label: 'N',
  //       name: 'Navigation',
  //       type: 'link',
  //       icon: 'ti-layout-cta-right'
  //     },
  //     {
  //       state: 'widget',
  //       short_label: 'W',
  //       name: 'Widget',
  //       type: 'sub',
  //       icon: 'ti-view-grid',
  //       badge: [
  //         {
  //           type: 'danger',
  //           value: '100+'
  //         }
  //       ],
  //       children: [
  //         {
  //           state: 'statistic',
  //           name: 'Statistic'
  //         },
  //         {
  //           state: 'data',
  //           name: 'Data'
  //         },
  //         {
  //           state: 'chart',
  //           name: 'Chart'
  //         }
  //       ]
  //     }
  //   ],
  // },
  // {
  //   label: 'UI Element',
  //   main: [
  //     {
  //       state: 'basic',
  //       short_label: 'B',
  //       name: 'Basic',
  //       type: 'sub',
  //       icon: 'ti-layout-grid2-alt',
  //       children: [
  //         {
  //           state: 'alert',
  //           name: 'Alert'
  //         },
  //         {
  //           state: 'breadcrumb',
  //           name: 'Breadcrumbs'
  //         },
  //         {
  //           state: 'button',
  //           name: 'Button'
  //         },
  //         {
  //           state: 'box-shadow',
  //           name: 'Box-Shadow'
  //         },
  //         {
  //           state: 'accordion',
  //           name: 'Accordion'
  //         },
  //         {
  //           state: 'generic-class',
  //           name: 'Generic Class'
  //         },
  //         {
  //           state: 'tabs',
  //           name: 'Tabs'
  //         },
  //         {
  //           state: 'color',
  //           name: 'Color'
  //         },
  //         {
  //           state: 'label-badge',
  //           name: 'Label Badge'
  //         },
  //         {
  //           state: 'progressbar',
  //           name: 'Progressbar'
  //         },
  //         {
  //           state: 'pre-loader',
  //           name: 'Pre-Loader'
  //         },
  //         {
  //           state: 'list',
  //           name: 'List'
  //         },
  //         {
  //           state: 'tooltip',
  //           name: 'Tooltip & Popover'
  //         },
  //         {
  //           state: 'typography',
  //           name: 'Typography'
  //         },
  //         {
  //           state: 'other',
  //           name: 'Other'
  //         },
  //       ]
  //     },
  //     {
  //       state: 'advance',
  //       short_label: 'A',
  //       name: 'Advance',
  //       type: 'sub',
  //       icon: 'ti-crown',
  //       children: [
  //         {
  //           state: 'modal',
  //           name: 'Modal'
  //         },
  //         {
  //           state: 'notifications',
  //           name: 'Notifications'
  //         },
  //         {
  //           state: 'notify',
  //           name: 'PNOTIFY',
  //           badge: [
  //             {
  //               type: 'info',
  //               value: 'New'
  //             }
  //           ]
  //         },
  //         {
  //           state: 'rating',
  //           name: 'Rating'
  //         },
  //         {
  //           state: 'slider',
  //           name: 'Slider'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'animations',
  //       short_label: 'A',
  //       name: 'Animations',
  //       type: 'link',
  //       icon: 'ti-reload rotate-refresh'
  //     }
  //   ]
  // },
  // {
  //   label: 'Forms',
  //   main: [
  //     {
  //       state: 'forms',
  //       short_label: 'F',
  //       name: 'Form',
  //       type: 'sub',
  //       icon: 'ti-layers',
  //       children: [
  //         {
  //           state: 'basic',
  //           name: 'Components'
  //         }, {
  //           state: 'add-on',
  //           name: 'Add-On'
  //         }, {
  //           state: 'advance',
  //           name: 'Advance'
  //         }, {
  //           state: 'validation',
  //           name: 'Validation'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'picker',
  //       short_label: 'P',
  //       main_state: 'forms',
  //       name: 'Form Picker',
  //       type: 'link',
  //       icon: 'ti-pencil-alt',
  //       badge: [
  //         {
  //           type: 'warning',
  //           value: 'New'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'select',
  //       short_label: 'S',
  //       main_state: 'forms',
  //       name: 'Form Select',
  //       type: 'link',
  //       icon: 'ti-shortcode'
  //     }
  //   ]
  // },
  // {
  //   label: 'Tables',
  //   main: [
  //     {
  //       state: 'bootstrap-table',
  //       short_label: 'B',
  //       name: 'Bootstrap Table',
  //       type: 'sub',
  //       icon: 'ti-receipt',
  //       children: [
  //         {
  //           state: 'basic',
  //           name: 'Basic Table'
  //         }, {
  //           state: 'sizing',
  //           name: 'Sizing Table'
  //         }, {
  //           state: 'border',
  //           name: 'Border Table'
  //         }, {
  //           state: 'styling',
  //           name: 'Styling Table'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'data-table',
  //       short_label: 'D',
  //       name: 'Data Table',
  //       type: 'sub',
  //       icon: 'ti-widgetized',
  //       children: [
  //         {
  //           state: 'basic',
  //           name: 'Basic Table'
  //         }, {
  //           state: 'editable',
  //           name: 'Editable'
  //         }, {
  //           state: 'row-details',
  //           name: 'Row Details Table'
  //         }, {
  //           state: 'paging',
  //           name: 'Paging Table'
  //         }, {
  //           state: 'selection',
  //           name: 'Selection Table'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: 'Chart And Map',
  //   main: [
  //     {
  //       state: 'charts',
  //       short_label: 'C',
  //       name: 'Charts',
  //       type: 'sub',
  //       icon: 'ti-bar-chart-alt',
  //       children: [
  //         {
  //           state: 'google',
  //           name: 'Google'
  //         }, {
  //           state: 'chart-js',
  //           name: 'ChartJS'
  //         }, {
  //           state: 'radial',
  //           name: 'Radial'
  //         }, {
  //           state: 'c3-js',
  //           name: 'C3 JS'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'map',
  //       short_label: 'M',
  //       name: 'Maps',
  //       type: 'sub',
  //       icon: 'ti-map-alt',
  //       children: [
  //         {
  //           state: 'google',
  //           name: 'Google'
  //         }
  //       ]
  //     },
  //     /*{
  //       state: 'landing',
  //       short_label: 'L',
  //       external: 'http://html.codedthemes.com/gradient-able-5/default/landingpage',
  //       name: 'Landing Page',
  //       type: 'external',
  //       icon: 'ti-mobile',
  //       target: true
  //     }*/
  //   ]
  // },
  // {
  //   label: 'Pages',
  //   main: [
  //     {
  //       state: 'auth',
  //       short_label: 'A',
  //       name: 'Authentication',
  //       type: 'sub',
  //       icon: 'ti-id-badge',
  //       children: [
  //         {
  //           state: 'login',
  //           type: 'sub',
  //           name: 'Login Pages',
  //           children: [
  //             {
  //               state: 'simple',
  //               name: 'Simple',
  //               target: true
  //             }, {
  //               state: 'header-footer',
  //               name: 'Header & Footer',
  //               target: true
  //             }, {
  //               state: 'social',
  //               name: 'Social Icon',
  //               target: true
  //             }, {
  //               state: 'social-header-footer',
  //               name: 'Social Header & Footer',
  //               target: true
  //             }
  //           ]
  //         }, {
  //           state: 'registration',
  //           type: 'sub',
  //           name: 'Registration Pages',
  //           children: [
  //             {
  //               state: 'simple',
  //               name: 'Simple',
  //               target: true
  //             }, {
  //               state: 'header-footer',
  //               name: 'Header & Footer',
  //               target: true
  //             }, {
  //               state: 'social',
  //               name: 'Social',
  //               target: true
  //             }, {
  //               state: 'social-header-footer',
  //               name: 'Social Header & Footer',
  //               target: true
  //             }
  //           ]
  //         },
  //         {
  //           state: 'forgot',
  //           name: 'Forgot Password',
  //           target: true
  //         },
  //         {
  //           state: 'lock-screen',
  //           name: 'Lock Screen',
  //           target: true
  //         },
  //       ]
  //     },
  //     {
  //       state: 'maintenance',
  //       short_label: 'A',
  //       name: 'Maintenance',
  //       type: 'sub',
  //       icon: 'ti-settings',
  //       children: [
  //         /*{
  //           state: 'error',
  //           name: 'Error'
  //         },
  //         {
  //           state: 'coming-soon',
  //           name: 'Coming Soon'
  //         },*/
  //         {
  //           state: 'offline-ui',
  //           name: 'Offline UI',
  //           target: true
  //         }
  //       ]
  //     },
  //     {
  //       state: 'user',
  //       short_label: 'U',
  //       name: 'User Profile',
  //       type: 'sub',
  //       icon: 'ti-user',
  //       children: [
  //         {
  //           state: 'profile',
  //           name: 'User Profile'
  //         }, {
  //           state: 'card',
  //           name: 'User Card'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: 'App',
  //   main: [
  //     {
  //       state: 'task',
  //       short_label: 'T',
  //       name: 'Task',
  //       type: 'sub',
  //       icon: 'ti-check-box',
  //       children: [
  //         {
  //           state: 'list',
  //           name: 'Task List'
  //         }, {
  //           state: 'board',
  //           name: 'Task Board'
  //         }, {
  //           state: 'details',
  //           name: 'Task Details'
  //         }, {
  //           state: 'issue',
  //           name: 'Issue List'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: 'Other',
  //   main: [
  //     {
  //       state: '',
  //       short_label: 'M',
  //       name: 'Menu Levels',
  //       type: 'sub',
  //       icon: 'ti-direction-alt',
  //       children: [
  //         {
  //           state: '',
  //           name: 'Menu Level 2.1',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.2',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.2',
  //               target: true
  //             }
  //           ]
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.3',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.4',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.2',
  //               target: true
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       state: 'simple-page',
  //       short_label: 'S',
  //       name: 'Simple Page',
  //       type: 'link',
  //       icon: 'ti-layout-sidebar-left'
  //     }
  //   ]
  // },
  // {
  //   label: 'Support',
  //   main: [
  //     /*{
  //       state: 'documentation',
  //       short_label: 'D',
  //       name: 'Documentation',
  //       external: 'http://html.codedthemes.com/guru-able/doc-angular-4',
  //       type: 'external',
  //       icon: 'ti-file',
  //       target: true
  //     },*/
  //     {
  //       state: 'submit-issue',
  //       short_label: 'S',
  //       external: 'http://bit.ly/2BVzkeg',
  //       name: 'Submit Issue',
  //       type: 'external',
  //       icon: 'ti-layout-list-post',
  //       target: true
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
