import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { ADFS } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private router: Router , private oauthService: OAuthService,) {    
     this.configure();
  }

  authCodeFlowConfig: AuthConfig = {
    issuer: ADFS.issuer,
    resource: ADFS.resource,
    redirectUri: ADFS.redirectUri,
    loginUrl: ADFS.loginUrl,
    tokenEndpoint: ADFS.tokenEndpoint,
    logoutUrl: ADFS.logoutUrl,
    clientId: ADFS.clientId,
    responseType: ADFS.responseType,
    scope: ADFS.scope,
    oidc: ADFS.oidc
  };

  private configure() {
    this.oauthService.configure(this.authCodeFlowConfig);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
