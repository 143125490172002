import { AgmAttachment } from './../model/setting.model';
import { ReturnApi } from './../model/Common.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AgmSelectedTab, AgreementList_Search, AgreementList, AgmHeader, UpdateStatusAgreement, AgmAprroval, ApprovAgmModel } from '../model/agreement.model';
import { RequestOptions, ResponseContentType } from '@angular/http';


// const httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
//       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
//     })
//   };
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};
  const API_Url = environment;
let BASEURL = '';
@Injectable()
export class AgreemenService {

  


constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

 } 
 getagmvalidatemainagm(Agm: any) {
      return this.http.post<any>(BASEURL + 'api/agreement/getagmvalidatemainagm/',Agm);
  }
 GetAgmReport(AgmID: number) {
  return this.http.get(BASEURL + 'api/agreement/GetAgmReport/'+AgmID,{responseType: 'blob'})
  }
 GetAgmReportByID(id: number):Observable<AgmHeader> {
  return this.http.get<AgmHeader>(BASEURL + 'api/agreement/GetAgmReportByID/'+id,  httpOptions).pipe(tap(response => { }));
 }

 GetAmgHeaderByID(id: number, Mode: string):Observable<AgmHeader> {
  return this.http.get<AgmHeader>(BASEURL + 'api/agreement/'+Mode + '/GetAgmHeaderByID/'+id,  httpOptions).pipe(tap(response => { }));

}
GetAgmapprovalByID(id: number) :Observable<Array<AgmAprroval>> {
  return this.http.get<Array<AgmAprroval>>(BASEURL + 'api/agreement/GetAgmapprovalByID/'+id,  httpOptions).pipe(tap(response => { }));

}

 Getagmlist(): Observable<any> {
 console.log('Send To API Getagmlist');
  return this.http.post<any>(BASEURL + 'api/agreement' + '/getagmlist', httpOptions).pipe(tap(response => { }));
  }
  GetagmlistSearch(Param: AgreementList_Search): Observable<Array<AgreementList>> {
  console.log('Send To API Getagmlist', );
    return this.http.post<Array<AgreementList>>(BASEURL + 'api/agreement' + '/getagmlist',Param,  httpOptions).pipe(tap(response => { }));
  }
  PreviewAgmReport(h:AgmHeader) {
    return this.http.post(BASEURL + 'api/agreement/PreviewAgmReport/',h,{responseType: 'blob'})
  }
  Save(h:AgmHeader):Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/agreement' + '/save',h,  httpOptions).pipe(tap(response => { }));
  }
  SaveAgmAttachment(h:AgmHeader):Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/agreement' + '/SaveAgmAttachment',h,  httpOptions).pipe(tap(response => { }));
  }
  updateDataFlag(param: UpdateStatusAgreement) {
    //update Contract Status to status cancel
    return this.http.put<any>(BASEURL + 'api/agreement' + '/updateStatus', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  uploadFile(id, data: FormData):Observable<ReturnApi> {
    const httpOptions = {
      headers: new HttpHeaders(
          {
              // 'Content-Type': 'application/json',
             // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
          }
      )
  };

    return this.http.post<ReturnApi>(BASEURL + 'api/agreement/'+id + "/upload/", data, httpOptions).pipe(
        tap((res: any) =>{},
        catchError(this.handleError<FormData>('uploadError')))
    );
}
Download(data :AgmAttachment): Observable<Blob> {
    return this.http.post(BASEURL + 'api/agreement/download/', data, { headers: httpOptions.headers, responseType: 'blob' })
}

SaveApproveAgm(data:ApprovAgmModel):Observable<ReturnApi> {
  return this.http.post<ReturnApi>(BASEURL + 'api/agreement' + '/saveApprovAgm',data,  httpOptions).pipe(tap(response => { }));
}
Sa
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.logA(`${operation} failed: ${error.message}`);

          // Let the app keep running by returning an empty result.
          return of(result as T);
      };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private logA(message: string) {
      console.log(message);
      // this.messageService.add('ScgCompanyService: ' + message);
  }
}

