import { log } from 'util';
import { Action_Permission } from './../../permission/permission';
import { Component, OnInit } from '@angular/core';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { Router } from '@angular/router';
import { User, GetUserPermissionPCResult } from '../../model/user.model';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../services/permission.service';
import { Compiler } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ADFS } from '../../../environments/environment';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('notificationBottom', [
      state(
        'an-off, void',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      state(
        'an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE
        })
      ),
      transition('an-off <=> an-animate', [animate('400ms ease-in-out')])
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '300px'
          // transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        'out',
        style({
          width: '0'
          // transform: 'translate3d(100%, 0, 0)'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight', [
      state(
        'nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      state(
        'nav-on',
        style({
          height: AUTO_STYLE
        })
      ),
      transition('nav-off <=> nav-on', [animate('400ms ease-in-out')])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AdminComponent implements OnInit {
  public navType: string;
  public themeLayout: string;
  public verticalPlacement: string;
  public verticalLayout: string;
  public pcodedDeviceType: string;
  public verticalNavType: string;
  public verticalEffect: string;
  public vnavigationView: string;
  public freamType: string;
  public sidebarImg: string;
  public sidebarImgType: string;
  public layoutType: string;

  public headerTheme: string;
  public pcodedHeaderPosition: string;

  public liveNotification: string;
  public liveNotificationClass: string;

  public profileNotification: string;
  public profileNotificationClass: string;

  public chatSlideInOut: string;
  public innerChatSlideInOut: string;

  public searchWidth: number;
  public searchWidthString: string;

  public navRight: string;
  public windowWidth: number;
  public chatTopPosition: string;

  public toggleOn: boolean;
  public navBarTheme: string;
  public activeItemTheme: string;
  public pcodedSidebarPosition: string;

  public menuTitleTheme: string;
  public dropDownIcon: string;
  public subItemIcon: string;

  public configOpenRightBar: string;
  public displayBoxLayout: string;
  public isVerticalLayoutChecked: boolean;
  public isSidebarChecked: boolean;
  public isHeaderChecked: boolean;
  public headerFixedMargin: string;
  public sidebarFixedHeight: string;
  public itemBorderStyle: string;
  public subItemBorder: boolean;
  public itemBorder: boolean;

  public config: any;
  currentUser: User = new User();

  currentLanguage: string;
  public ACTION_PMS = Action_Permission;
  permissionList: Array<GetUserPermissionPCResult>;
  id: number;
  NotiCount: number;

  constructor(
    public menuItems: MenuItems,
    public translate: TranslateService,
    public _permissionService: PermissionService,
    private _router: Router,
    private _compiler: Compiler,
    private oauthService: OAuthService,
    private _settingService:SettingService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.id = this.currentUser.UserId;
    this.currentLanguage = localStorage.getItem('Language');
    // const snapshot: RouterStateSnapshot = _router.routerState.snapshot;
    // this.URL = snapshot.url;
    //console.log(this.currentUser);
    if (this.currentUser) {
      this.permissionList = this.currentUser.Permission;
       console.log(menuItems.getAll());

    }
 
    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = 'theme1';
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = 'light';

    this.headerTheme = 'themelight2';
    // this.headerTheme = 'themelight5';
    this.pcodedHeaderPosition = 'fixed';

    this.menuTitleTheme = 'theme6';
    this.navBarTheme = 'theme';

    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';

    this.chatSlideInOut = 'out';
    this.innerChatSlideInOut = 'out';

    this.searchWidth = 0;

    this.navRight = 'nav-on';

    this.windowWidth = window.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    this.toggleOn = true;

    // this.navBarTheme = 'themelight1';
    // this.menuTitleTheme = 'theme2';

    this.activeItemTheme = 'theme8';
    this.pcodedSidebarPosition = 'fixed';

    this.dropDownIcon = 'style3';
    this.subItemIcon = 'style7';

    this.displayBoxLayout = 'd-none';
    this.isVerticalLayoutChecked = false;
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    this.headerFixedMargin = '56px';
    this.sidebarFixedHeight = 'calc(100vh - 56px)';
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.itemBorder = true;

    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);
  }
  GetExternal(key){
this._settingService.GetSystemConfig('Report').subscribe(s=>{
 var find = s.find(d=>d.SeqID==key);
 if(find){
  //location.href = find.ConfigValue;
  window.open(find.ConfigValue, "_blank");
 }
})
      
  }
  Profile() {
    this._router.navigate(['/user/detail'], { queryParams: { id: this.id, from: 'profile' } });
  }
  ngOnInit() {
    this.setBackgroundPattern('pattern1');
    this._settingService. getcountpendingapprovecontractagm(this.currentUser.UserId).subscribe(s=>{
      this.NotiCount =s;
    })
    /*document.querySelector('body').classList.remove('dark');*/
  }
  CheckMenuTitle(item) {
    let childHasView = false;
    let HasChildren = false;
    if (item.main && item.main.length > 0) {
      item.main.forEach(element => {

        if (element.children && element.children.length > 0) {
          element.children.forEach(child => {
            HasChildren = true;
            if (this.checkPermission(child.p_type, this.ACTION_PMS.VIEW)) {
              childHasView = true;
              return;
            }
          });
        }
        if (childHasView) return;
        if (this.checkPermission(element.p_type, this.ACTION_PMS.VIEW)) {
          if (!HasChildren)
            childHasView = true;
          return;
        }
      });
      if (childHasView) return childHasView;
    }
    //console.log(item);
    return false;

  }
  CheckMenuSubTitle(item) {
    let childHasView = false;
    if (item.children && item.children.length > 0) {
      item.children.forEach(child => {
        if (this.checkPermission(child.p_type, this.ACTION_PMS.VIEW)) {
          childHasView = true;
          return;
        }
      });
    }
    if (childHasView) return childHasView;
    // if (this.checkPermission(item.p_type, this.ACTION_PMS.VIEW)) {
    //   return true;
    // }
    //console.log(item);
    return false;

  }
  AnyTrue(obj) {
    for (var o in obj)
      if (obj[o]) return true;

    return false;
  }
  logout() {
    window.localStorage.removeItem('currentUser');
    // this._router.navigate(['/login']);
    this._compiler.clearCache();
    location.href = ADFS.logoutUrl;

    // this._authService.logout().subscribe(
    //   data => {
    //     window.localStorage.removeItem('currentUser');
    //     this._router.navigate(['/login']);
    //     // window.location.replace('/login');

    //   },
    //   error => {

    //   });
  }

  checkPermission(Type: string, Action: number): boolean {

    return this._permissionService.CheckPermission(Type, Action);
  }

  changeLanguage(lang) {
    console.log('changeLanguage : ', lang);
    window.localStorage.setItem('Language', lang);
    this.currentLanguage = localStorage.getItem('Language');
    this.translate.use(this.currentLanguage);
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (
      this.pcodedDeviceType === 'tablet' &&
      this.windowWidth >= 768 &&
      this.windowWidth <= 1024
    ) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth < 992) {
      this.navRight = 'nav-off';
    } else {
      this.navRight = 'nav-on';
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.pcodedDeviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
    this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';
    if (this.navRight === 'nav-off' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.navRight === 'nav-off' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleLiveNotification() {
    this.liveNotification =
      this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass =
      this.liveNotification === 'an-animate' ? 'active' : '';

    if (
      this.liveNotification === 'an-animate' &&
      this.innerChatSlideInOut === 'in'
    ) {
      this.toggleInnerChat();
    }
    if (
      this.liveNotification === 'an-animate' &&
      this.chatSlideInOut === 'in'
    ) {
      this.toggleChat();
    }
  }

  toggleProfileNotification() {
    this.profileNotification =
      this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass =
      this.profileNotification === 'an-animate' ? 'active' : '';

    if (
      this.profileNotification === 'an-animate' &&
      this.innerChatSlideInOut === 'in'
    ) {
      this.toggleInnerChat();
    }
    if (
      this.profileNotification === 'an-animate' &&
      this.chatSlideInOut === 'in'
    ) {
      this.toggleChat();
    }
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
  }

  toggleChat() {
    this.chatSlideInOut = this.chatSlideInOut === 'out' ? 'in' : 'out';
    if (this.innerChatSlideInOut === 'in') {
      this.innerChatSlideInOut = 'out';
    }
  }

  toggleInnerChat() {
    this.innerChatSlideInOut =
      this.innerChatSlideInOut === 'out' ? 'in' : 'out';
  }

  searchOn() {
    document.querySelector('#main-search').classList.add('open');
    const searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  searchOff() {
    const searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector('#main-search').classList.remove('open');
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  toggleOpened() {
    if (this.windowWidth < 992) {
      this.toggleOn =
        this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      if (this.navRight === 'nav-on') {
        this.toggleHeaderNavRight();
      }
    }
    this.verticalNavType =
      this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
  }

  onClickedOutsideSidebar(e: Event) {
    if (
      (this.windowWidth < 992 &&
        this.toggleOn &&
        this.verticalNavType !== 'offcanvas') ||
      this.verticalEffect === 'overlay'
    ) {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.sidebarImg = 'false';
      console.log('here1');
    } else {
      this.menuTitleTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.sidebarImg = 'false';
      console.log('here2');
    }
  }

  setLayoutType(type: string) {
    console.log('setLayoutType');

    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme1';
      this.sidebarImg = 'false';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else if (type === 'light') {
      this.sidebarImg = 'false';
      this.headerTheme = 'theme5';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    } else if (type === 'img') {
      this.sidebarImg = 'true';
      this.headerTheme = 'theme1';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setVerticalLayout() {
    this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
    if (this.isVerticalLayoutChecked) {
      this.verticalLayout = 'box';
      this.displayBoxLayout = '';
    } else {
      this.verticalLayout = 'wide';
      this.displayBoxLayout = 'd-none';
    }
  }

  setBackgroundPattern(pattern: string) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition =
      this.isSidebarChecked === true ? 'fixed' : 'absolute';
    this.sidebarFixedHeight =
      this.isHeaderChecked === true
        ? 'calc(100vh + 56px)'
        : 'calc(100vh - 56px)';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition =
      this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '56px' : '';
  }
}
