import { AgmAttachment } from './../model/setting.model';
import { ReturnApi } from './../model/Common.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AgmSelectedTab, AgreementList_Search, AgreementList, AgmHeader, UpdateStatusAgreement, AgmAprroval, ApprovAgmModel } from '../model/agreement.model';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { MasterSearch, GetSupplierList, GetSupplierBySupplierId, UpdateSupplier } from '../model/master.model';


// const httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
//       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
//     })
//   };
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};
  const API_Url = environment;
let BASEURL = '';
@Injectable()
export class MasterService {


constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

}
 Getagmlist(): Observable<any> {
 console.log('Send To API getMasterList');
  return this.http.post<any>(BASEURL + 'api/master/getMasterList', httpOptions).pipe(tap(response => { }));
  }
  GetSupplierlistSearch(Param: MasterSearch): Observable<Array<GetSupplierList>> {
  console.log('Send To API GetSupplierList', );
    return this.http.post<Array<GetSupplierList>>(BASEURL + 'api/master/getMasterList',Param,  httpOptions).pipe(tap(response => { }));
  }
  GetSupplierBySupplierId(SupplierId: number):Observable<GetSupplierBySupplierId> {
  return this.http.get<GetSupplierBySupplierId>(BASEURL + 'api/master/' + 'getSupplier/' + SupplierId,  httpOptions).pipe(tap(response => { }));
  
  }
  UpdateSupplier(param: UpdateSupplier) {
    return this.http.post<any>(BASEURL + 'api/master' + '/UpdateSupplier', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  
Sa
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.logA(`${operation} failed: ${error.message}`);

          // Let the app keep running by returning an empty result.
          return of(result as T);
      };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private logA(message: string) {
      console.log(message);
      // this.messageService.add('ScgCompanyService: ' + message);
  }
}

