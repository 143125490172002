import { WarehouseService } from './services/warehouse.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { VendorService } from './services/vendor.service';
import { CustomerService } from './services/customer.service';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { UserService } from './services/user.service';
import { SettingService } from './services/setting.service';
import { AuthenService } from './services/authen.service';
import { AuthGuard } from './guard/_guards/auth.guard';
import { ClickOutsideModule } from 'ng-click-outside';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { MasterService } from './services/master.service';
import { SavefileService } from './services/savefile';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PermissionService } from './services/permission.service';
import { GoodIssueService } from './services/GoodIssue.service';
import { ModalService } from './services/modal.service';
import { HttpModule } from '@angular/http';
import { DebounceClickDirective } from './pages/e-pur/goods-issue/debounce-click.directive';
import { ContractService } from './services/Contract.service';
import { AgreemenService } from './services/agreemen.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from './services/template.service';
import { PIService } from './services/PI.Service';
//import { AgreementAPListComponent } from './pages/Approve/agreement-ap-list/agreement-ap-list.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthInterceptor } from './directive/auth-interceptor';
import { ErrorInterceptor } from './directive/error.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, AdminComponent, AuthComponent, BreadcrumbsComponent],
  imports: [
    BrowserModule,
    ClickOutsideModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    Ng4LoadingSpinnerModule.forRoot(),
    HttpClientModule,
    HttpModule,
    
    NgbModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true
    })
  ],
  providers: [
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    MenuItems,
    VendorService,
    CustomerService,
    WarehouseService,
    UserService,
    AgreemenService,
    SettingService,
    AuthenService,
    MasterService,
    PermissionService,
    SavefileService,
    GoodIssueService,
    ModalService,
    DatePipe,
    ContractService,
    TemplateService,
    PIService,
    MasterService
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule {}
