export class User {

  UserId: number;
  EmpCode: string;
  Email: string;
  FirstName: string;
  LastName: string;
  UserName: string;
  Password: string;
  CompanyID: number;
  LastLoginDate: string;
  Status: number;
  Lanauge: string;
  DateFormat: string;
  Theme: string;
  CurrencyFormat: string;
  CurrencyDecimalSeparator: string;
  CurrencyGroupSeparator: string;
  FormatType: string;
  RecordsPerPage: string;
  EnableTooltip: string;
  Deleted: boolean;
  CreateDate: string;
  CreateBy: string;
  CreatePcNm: string;
  UpdateDate: string;
  UpdateBy: string;
  UpdatePcNm: string;
  CompanyName: string;
  BaseCurrency: string;
  token: string;
  IPAddress: string;
  SelectedValue: any;
  LocationID: string;
  GroupID: number;
  LocationName: string;
  LocationCode: string;
  IsAdmin: boolean;
  LocationCodeName: string;

  Permission: Array<GetUserPermissionPCResult>;
  CpoPosition: string;
}
export class UserLogin {
  UserName: string;
  Password: string;
  IPAddress: string;
  Email: string;
  Code: string;
  token: any;
}

export class ChangePassword {
  UserID: number;
  UserName: string;
  oldPassword: string;
  newPassowrd: string;
  confirmPassowrd: string;
  user: number;
  CompanyID: number;
  URL: string;
  IPAddress: string;
}

export class GetAllUIobjectResult {
  constructor() {
    this.Permission = new Array<GetUIobjectPermissionResult>();
  }
  ObjectID: string;
  ObjName: string;
  Description: string;
  ObjType: string;
  ObjTypename: string;
  View: boolean = false;
  Create: boolean = false;
  Edit: boolean = false;
  Delete: boolean = false;
  Print: boolean = false;
  Export: boolean = false;
  Approve: boolean = false;
  isDisable: boolean;
  Permission: Array<GetUIobjectPermissionResult>;
}

export class GetUIobjectPermissionResult {
  Module: boolean;
  GroupID: number;
  ObjID: string;
  PermissionID: number;
  Description: string;
  PermissionType: number;
  PermissionName: string;
  PermissionFlag: boolean;
  UpdateBy: number;
  isDisable: boolean;
}

export class UIobjectPermissionSearch {
  ObjectID: string;
  GroupId: number;
}

export class GetGroupListSearch {
  GroupID: number;
  GroupCode: string;
  GroupName: string;
  Description: string;
}

export class Group {
  GroupID: number;
  GroupCode: string;
  GroupName: string;
  Description: string;
  CreateBy: number;
  CreateDate: string;
  CreateName: string;
  UpdateBy: number;
  UpdateName: string;
  UpdateDate: string;
}

export class GroupPermissionModel extends Group {
  UpdatePcNm: string;
  CreatePcNm: string;
  constructor() {
    super();
    this.Permissions = new Array<GetUIobjectPermissionResult>();
  }
  Permissions: Array<GetUIobjectPermissionResult>;
}

export class GetLoginHistoryResult {
  LoginID: number;
  UserID: number;
  UserName: string;
  Email: string;
  LoginDate: string;
  LogOutDate: string;
  IPAddress: string;
  SessionID: string;
  SuccessFlag: boolean;
  Error: string;
}

export class GetUserPermissionPCResult {
  GroupID: number;
  ObjID: string;
  ObjName: number;
  PermissionID: number;
  Permissionflag: boolean;
  ObjType: string;
  Description: string;
}

export class UserModels {
  Manage: string;
  UserId: number;
  UserName: string;
  Email: string;
  Title: string;
  FirstName: string;
  LastName: string;
  DeptCode: string;
  IsVendorFlag: boolean;
  ShippingPointed: string;
  SalesOrged: string;
  Role: string;
  Level: number;
  ActiveFlag: boolean;
  CreateBy: number;
  CreateDate: number;
  UpdateBy: number;
  UpdateDate: number;
  FullName: string;
  Position: string;
  PurchaseOrg: string;
  Telephone: string;
  Faxno: string;
  usergroup: Array<GroupUser>;
  SalesOrgsList: Array<string>;
  ShippingPointsList: Array<string>;
  SalesOrgsName: Array<string>;
  ShippingPointsName: Array<string>;
  PurchaseOrgName: string;
  DeptName: string;
  RoleName: string;
  constructor() {
    this.usergroup = new Array<GroupUser>();
    this.SalesOrgsList = new Array<string>();
    this.ShippingPointsList = new Array<string>();
    this.SalesOrgsName = new Array<string>();
    this.ShippingPointsName = new Array<string>();
  }
}

export class GroupUser {
  groupid: number;
  text: string;
  value: string;
  checkeds: boolean;
}

export class Status {
  TransactionType: number;
  StatusID: number;
  StatusCode: string;
  StatusName: string;
  Description: string;
  StatusBgColor: string;
  StatusBorderColor: string;
  StatusFontColor: string;
  StatusFontSize: number;
}

export class Department {
  DeptCode: string;
  DeptName: string;
}

export class UserType {
  ConfigID: number;
  SeqID: number;
  ConfigCode: string;
  ConfigValue: string;
  ConfigDisplay: string;
  Description: string;
  CreateBy: string;
  CreateDate: number;
  UpdateBy: string;
  UpdateDate: number;
}

export class UserQuery {
  pname: string;
  pusername: string;
  pdeptcode: string;
  pactiveflag?: boolean;
  pvendorflag?: boolean;
}

export class GroupList {
  GroupID: number;
  GroupCode: string;
  GroupName: string;
  Description: string;
  UpdateBy: number;
  UpdateDate: number;
  Checkbox: boolean;
}

export class Saleorg {
  SalesOrgCode: string;
  SalesOrgName: string;
  Description: string;
}
export class Shippingpoint {
  ShippingPointCode: string;
  ShippingPointName: string;
  Description: string;
}
export class Role {
  RoleCode: string;
  RoleName: string;
  Description: string;
}

export class DupicateUser {
  userId: number;
  username: string;
}

export class DupicateGroupCode {
  userId: number;
  GroupCode: string;
}

export class UIobject {
  ObjectID: string;
  ObjName: string;
  Description: string;
  ObjType: string;
  ObjEnable: Boolean;
  SortBy: string;
  CreateBy: number;
  CreateDate: number;
  UpdateBy: number;
  UpdateDate: number;
}


export class Userreturn {
  userid: number;
  success: boolean;
  message: string;
}

export class Purorg {
  PurOrgCode: string;
  PurOrgName: string;
  Description: string;
}

export class DelegateItem {
  DelegateUserId: number;
  DelegateType: string;
  DateTo: Date;
  DateFrom: Date;
  UpdateBy: Date;
  hasvalue: boolean;
}

export class DeletegateByid {
  User_delegate_List: Array<User_Delegate>;
  PI_Delegate_User: Array<Delegate_User>;
  CT_Delegate_User: Array<Delegate_User>;
  AGM_Delegate_User: Array<Delegate_User>;
  constructor() {
    this.User_delegate_List = new Array<User_Delegate>();
    this.PI_Delegate_User = new Array<Delegate_User>();
    this.CT_Delegate_User = new Array<Delegate_User>();
    this.AGM_Delegate_User = new Array<Delegate_User>();
  }
}

export class Delegate_User {
  FirstName: string;
  LastName: string;
  Fullname: string;
  UserId: number;
}

export class User_Delegate {
  ID: number;
  DelegateUserId: number;
  DelegateType: string;
  DelegateUserName: string;
  DateTo: Date;
  DateFrom: Date;
  UpdateBy: number;
  hasvalue: boolean;
}

export class UIObjectPermission {
  ObjectId: string;
  PermissionId: number;
  Createby: number;
  CreateDate: Date;
}