import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  User,
  ChangePassword,
  GetAllUIobjectResult,
  UIobjectPermissionSearch,
  GetUIobjectPermissionResult,
  Group,
  GetLoginHistoryResult,
  GroupPermissionModel,
  GetGroupListSearch,
  GetUserPermissionPCResult,
  UserModels,
  Status,
  Department,
  UserType,
  UserQuery,
  GroupList,
  Shippingpoint,
  Saleorg,
  Role,
  DupicateUser,
  DupicateGroupCode
} from '../model/user.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

const API_Url = environment;
let BASEURL = '';
@Injectable()
export class UserService {
  private Url = ''; // Url to web api
  currentUser: any;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;
    this.Url = API_Url + '/' + '1' + '/user';
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.Url = API_Url + '/' + ( '1') + '/user';
    // console.log(this.currentUser);
  }

  getUsers(id: number): Observable<UserModels> {
    return this.http.get<UserModels>(BASEURL + 'api/user/Getuserbyid/' + id);
  }

  getUserList(value: UserQuery): Observable<UserModels[]> {
    return this.http.post<UserModels[]>(BASEURL + 'api/user/GetUserList', value);
  }

  listDept(): Observable<Department[]> {
    return this.http.get<Department[]>(BASEURL + 'api/user/GetDept');
  }

  listTypeUser(type: string): Observable<UserType[]> {
    return this.http.get<UserType[]>(BASEURL + 'api/user/listTypeUser/' + type);
  }

  getGroupList(value: any): Observable<GroupList[]> {
    const va = Object.assign({ SearchValue: value });
    console.log('va', va);
    return this.http.post<GroupList[]>(BASEURL + 'api/user/GroupList/', va);
  }

  GetSaleorg(): Observable<Saleorg[]> {
    return this.http.get<Saleorg[]>(BASEURL + 'api/user/GetSaleorg');
  }

  GetShippingpoint(): Observable<Shippingpoint[]> {
    return this.http.get<Shippingpoint[]>(BASEURL + 'api/user/GetShippingpoint');
  }

  GetRole(): Observable<Role[]> {
    return this.http.get<Role[]>(BASEURL + 'api/user/GetRole');
  }

  Getusergroupbyid(Id: Number): Observable<GroupList[]> {
    return this.http.get<GroupList[]>(BASEURL + 'api/user/Getusergroupbyid/' + Id);
  }

  AddUser(value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/user/ManageUser', value);
  }

  UpdateUser(value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/user/ManageUser', value);
  }

  Checkduplicateusername(username: DupicateUser): Observable<any> {
    return this.http.post(BASEURL + 'api/user/Checkduplicateusername/', username);
  }

  GetGroupByID(id: number): Observable<any> {
    return this.http.get(BASEURL + 'api/user/GetGroupByID/' + id);
  }

  Getgrouppermission(id: number): Observable<any> {
    return this.http.get(BASEURL + 'api/user/Getgrouppermission/' + id);
  }

  Getuiobject(): Observable<any> {
    return this.http.get(BASEURL + 'api/user/Getuiobject');
  }

  GroupCodeDupicate(groupCode: DupicateGroupCode): Observable<any> {
    return this.http.post(BASEURL + 'api/user/Checkduplicategroupcode', groupCode);
  }

  GroupManage(Value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/user/GroupManage', Value);
  }

  Getpermissionlist(): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/user/Getpermissonlist');
  }

  PurOrg(): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/user/GetPurorg');
  }

  Getuserdelegatebyuserid(value: string): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/user/Getuserdelegatebyuserid/' + value);
  }

  Deletedelegate(value: any): Observable<any> {
    return this.http.post<any[]>(BASEURL + 'api/user/Deletedelegate/', value);
  }

  AddUpdateDelegate(value: any): Observable<any> {
    return this.http.post<any[]>(BASEURL + 'api/user/AddUpdateDelegate/', value);
  }

  GetUIObjectPermission(): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/user/GetUIObjectPermission/');
  }

  GetUserForExport(type: number): Observable<any> {
    return this.http
      .post(this.Url + '/GetUserForExport/' + type, null, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'arraybuffer'
      })
      .pipe(
        tap(x => { }),
        catchError(this.handleError<{}>('SearchWorkPermit'))
      );
  }

  ChangePassword(data: ChangePassword): Observable<any> {
    return this.http.post<any>(this.Url + '/ChangePassword', data, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  ChangePasswordWithAdmin(data: ChangePassword): Observable<any> {
    return this.http.post<any>(this.Url + '/ChangePasswordWithAdmin', data, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  // AddUser(data: User): Observable<any> {
  //     console.log(data)
  //     return this.http.post<any>(this.Url + '', data, httpOptions)
  //         .pipe(
  //             tap(response => {
  //                 // this.log(`fetched `)
  //             })
  //         );
  // }

  GetUserByID(id: number): Observable<User> {
    return this.http.get<User>(this.Url + '/' + id.toString(), httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  GetAllUIobject(): Observable<Array<GetAllUIobjectResult>> {
    return this.http.get<Array<GetAllUIobjectResult>>(this.Url + '/GetAllUIobject', httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  // GetGroupByID(GroupId: number): Observable<any> {
  //     return this.http.get<any>(this.Url + '/GetGroupByID/' + GroupId.toString(), httpOptions)
  //         .pipe(
  //             tap(response => {
  //                 // this.log(`fetched `)
  //             })
  //         );
  // }

  GetUIobjectPermission(search: UIobjectPermissionSearch): Observable<Array<GetUIobjectPermissionResult>> {
    return this.http.post<Array<GetUIobjectPermissionResult>>(this.Url + '/GetUIobjectPermission/', search, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }
  GetLoginHistory(id: number): Observable<GetLoginHistoryResult[]> {
    return this.http.get<GetLoginHistoryResult[]>(this.Url + '/GetLoginHistory' + '/' + id, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  GetUserPermissionPC(id: number): Observable<GetUserPermissionPCResult[]> {
    return this.http.get<GetUserPermissionPCResult[]>(this.Url + '/GetUserPermissionPC' + '/' + id, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  // AddGroupPermission(data: GroupPermission): Observable<any> {
  //     return this.http.post<any>(this.Url + '/AddGroupPermission/', data, httpOptions)
  //         .pipe(
  //             tap(response => {
  //                 // this.log(`fetched `)
  //             })
  //         );
  // }

  AddGroup(data: GroupPermissionModel): Observable<any> {
    return this.http.post<any>(this.Url + '/AddGroup/', data, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  // UpdateUser(id: number, data: User): Observable<any> {
  //     return this.http.put<any>(this.Url + '/' + id.toString(), data, httpOptions)
  //         .pipe(
  //             tap(response => {
  //                 // this.log(`fetched `)
  //             })
  //         );
  // }

  GetGroupList(search: GetGroupListSearch): Observable<Array<Group>> {
    return this.http.post<Array<Group>>(this.Url + '/GetGroupList/', search, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private log(message: string) {
    console.log(message);
    // this.messageService.add('ScgCompanyService: ' + message);
  }
}
