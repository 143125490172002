import { SmartItem } from "./GoodsIssue.model";

export class SelectItem {
    ItemID: number;
    ItemCode: string;
    ItemName: string;
    Description: string;
}

export class Attachment {
    AttachmentID: number;
    AttachmentType: string;
    RefID: string;
    DocNo: number;
    DocTitle: string;
    CompanyID: string;
    Path: string;
    Description: string;
    CreateDate: string;
    CreateBy: string;
    UpdateDate: string;
    UpdateBy: string;
}

export class ClassApiSearch {
    PageIndex: number = 1;
    RecsPerPage: number;
    TotalRecords: number;
    SortBy: string;
    SortDirection: string;
}

export class ReturnApi {
    Data: any;
    // IsDone: boolean;
    // Message: string;
    Success: boolean;
    Msg:string;
}

export class Country {
    ID: number;
    CountryID: number;
    Language: string;
    CountryName: string;
    Deleted: string;
}

export class PaymentTerm {
    TermID: number;
    CompanyID: number;
    Term: string;
    Description: string;
    CreateDate: string;
    CreateBy: string;
    UpdateDate: string;
    UpdateBy: string;
}

export class AddressSearch {
    CustomerID: number;
    AddressType: string;
}

export class CommonSearch {
    Code: string = null;
    Name: string = null;
}

export class ForwarderListResult {
    ForwarderID: number;
    ForwarderCode: string;
    ForwarderName: string;
    ForwarderNameTH: string;
    CreateDate: string;
    CreateBy: number;
    UpdateDate: string;
    UpdateBy: number;;
}

export class ShipmentTypeListResult {
    ShipmentTypeID: number;
    ShipmentTypeCode: string;
    ShipmentTypeName: string;
    ShipmentTypeNameTH: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class ShipmentClassifyListResult {
    ShipmentClassifyID: number;
    ShipmentClassifyCode: string;
    ShipmentClassifyName: string;
    CreateDShipmentClassifyNameTHate: string;
    CreateDate: string;
    CreateBy: number;
    UpdateDate: string;
    UpdateBy: number;
}

export class TruckTypeResult {
    TruckTypeID: number;
    TruckTypeCode: string;
    TruckTypeName: string;
    CreateDate: string;
    CreateBy: number;
    UpdateDate: string;
    UpdateBy: number;
}

export class Printer {
    printerType: boolean;
}

export class T_RETURN_CLASS {
    TYPE: string;
    ID: string;
    NUMBER: string;
    MESSAGE: string;
    LOG_NO: string;
    LOG_MSG_NO: string;
    MESSAGE_V1: string;
    MESSAGE_V2: string;
    MESSAGE_V3: string;
    MESSAGE_V4: string;
    PARAMETER: string;
    ROW: string;
    FIELD: string;
    SYSTEM: string;
}

export class GI_T_RETURN_CLASS {
    E_APP_NAME: string;
    E_INF_ID: string;
    E_INF_NAME: string;
    E_SAPPI_ID: string;
    T_RETURN: SmartItem
    constructor() {
        this.T_RETURN = new SmartItem();
    }
}

export class Reverse_GI {
    I_APP_NAME: string;
    I_INF_ID: string;
    I_INF_NAME: string;
    I_LIFEX: string;
    I_OPTION: string;
    I_REVERSE_DATE: string;
    I_SAPPI_ID: string;
    I_VBELN: string;
    I_GI_DATE: string;
}

export class Log_GI {
    asalechannel: string;
    aapiname: string;
    aservername: string;
    aiappname: string;
    aiinfname: string;
    aiinfid: string;
    aisappiid: string;
    aivbeln: string;
    ailifex: string;
    aigidate: string;
    aioption: string;
    aiflagso: string;
    aireversedate: string;
    aconnectstatus: string;
    amessage: string;
    acreateby: string;
    all_item: string;
    all_T_Return: string;
}

export const TRETURN_TYPE_ENUM = {
    Success: 'S',
    Error: 'E',
    Warning: 'W',
    Info: 'I',
    Abort: 'A'

}
 
   
export const AgmTemplate_AgmTabCode = {
    Credit: 'Credit',
    Rebate:'Rebate',
    MKT:'MKT',
    DCCharge:'DCCharge',
    Damage:'Damage',
    Customize:'Customize',
    NewStore:'NewStore',
    NewProduct:'NewProduct',
}
export const AgmTemplate_AttrCode = {
    Period: 'Period',
    SupType: 'SupType',
    CalBy:'CalBy',
    PaymentMethod:'PaymentMethod',

}
export const PublicValue = {
    I_APP_NAME: 'S',
    ScreenMode:{
        Add: 'AD',
        Copy: 'CP',
        Edit: 'ED',
        View: 'View',
    }
}
