import { ContractApproval, UpdateContractApproval, ContractTemplateDetail, Contract_Detail, ParamReport_Contract, ContractHeader_Att } from './../model/Contract.model';
import { SystemAttachDoc, Supplier } from './../model/setting.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { UpdateStatusContract, ContractAttachment, GetContractByID } from '../model/Contract.model';
import { tap, catchError } from 'rxjs/operators';
import { Contract_Search } from '../model/setting.model';
import { ReturnApi } from '../model/common.model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  })
};

const API_Url = environment;
let BASEURL = '';
@Injectable()
export class ContractService {

  constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

  }
  Getagmlist(): Observable<any> {
    debugger; console.log('Send To API Getagmlist');
    return this.http.post<any>(BASEURL + 'api/agreement' + '/getagmlist', httpOptions).pipe(tap(response => { }));
  }

  // GetAllDataContractList(): Observable<any> {
  //   return this.http.post<any>(BASEURL + 'api/contract' + '/contractList', httpOptions).pipe(tap(response => { }));
  //   // return this.http.post<any>(BASEURL + 'api/agreement' + '/getagmlist',  httpOptions).pipe(tap(response => { }));
  // }
  GetContractSearch(SearchParam?: Contract_Search): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/contract' + '/contractList', SearchParam, httpOptions).pipe(tap(response => { }));
    // return this.http.post<any>(BASEURL + 'api/agreement' + '/getagmlist',  httpOptions).pipe(tap(response => { }));
  }
  GetContractApproveSearch(SearchParam?: Contract_Search): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/contract' + '/contractApproveList', SearchParam, httpOptions).pipe(tap(response => { }));
    // return this.http.post<any>(BASEURL + 'api/agreement' + '/getagmlist',  httpOptions).pipe(tap(response => { }));
  }
  // GetStatus() {
  // 	return this.http.get<any>(BASEURL + 'api/status' + '/all');
  // }

  updateDataFlag(param: UpdateStatusContract) {
    //update Contract Status to status cancel
    return this.http.put<any>(BASEURL + 'api/contract' + '/updateFlag', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  CancelDocument(param: UpdateStatusContract) {
    //update Contract Status to status cancel
    return this.http.put<any>(BASEURL + 'api/contract' + '/CancelContract', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  updateDataContractApproval(param: UpdateContractApproval) {
    //update Contract Status to status cancel
    return this.http.put<any>(BASEURL + 'api/contract' + '/updateContractApproval', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  getAgmActivebyID(RefConID: number) {
    return this.http.get<any>(BASEURL + 'api/contract' + '/getAgmActive/' + RefConID, httpOptions)
  }
  getApprovalbyID(ContractId: number) {
    return this.http.get<any>(BASEURL + 'api/contract' + '/getApprovalById/' + ContractId, httpOptions)
  }
  getContacrtbyID(ContractId: number) {
    return this.http.get<any>(BASEURL + 'api/contract' + '/getContractById/' + ContractId, httpOptions)
  }
  getContacrtTemplateByStatus(Status: number) {
    return this.http.get<any>(BASEURL + 'api/contract' + '/getContracttemplate/' + Status, httpOptions)
  }
  getPDF(model?: Contract_Detail[]) {
    return this.http.post(BASEURL + 'api/contract' + '/HTML2PDF/', model, { responseType: 'blob' })
  }
  getPDFtoShow(data: ParamReport_Contract) {
    return this.http.post(BASEURL + 'api/contract' + '/ShowPDF/', data, { responseType: 'blob' })
  }
 
  GetContract_DetailReplacevalue(DataReplace: ParamReport_Contract): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/contract' + '/DataDetail', DataReplace, httpOptions).pipe(tap(response => { }));
  }
  // GetContractforReportByID(ConID: number) {
  //   return this.http.get(BASEURL + 'api/contract/GetContractReportByID/' + ConID)
  // }
  GetContractReport(ConID: number) {
    return this.http.get(BASEURL + 'api/contract/GetContractReport/' + ConID, { responseType: 'blob' })
  }
  // exportToPDF(model:DocumentResignReq[]){
  //   return this.http.post(this.baseUrl + "/resignPDF",model,{responseType: 'blob'});
  // }
  SaveContractAttachment(ContractAtt: ContractHeader_Att): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/contract' + '/SaveContractAttachment', ContractAtt, httpOptions).pipe(tap(response => { }));
  }
  Save(header: GetContractByID): Observable<any> { //ReturnApi
    // console.log("header",header);
    return this.http.post<any>(BASEURL + 'api/contract' + '/SaveData', header, httpOptions).pipe(tap(response => { }));
  }

  SaveSupp(Supp: Supplier): Observable<any> { //ReturnApi
    console.log("Supp", Supp);
    return this.http.post<any>(BASEURL + 'api/contract' + '/NSupp', Supp, httpOptions).pipe(tap(response => { }));
  }

  uploadFile(id, data: FormData): Observable<ReturnApi> {
    // console.log("id", id, data);

    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };

    return this.http.post<ReturnApi>(BASEURL + 'api/contract/' + id + "/upload/", data, httpOptions).pipe(
      tap((res: any) => { },
        catchError(this.handleError<FormData>('uploadError')))
    );
  }
  Download(data: ContractAttachment): Observable<Blob> {
    return this.http.post(BASEURL + 'api/contract/download/', data, { headers: httpOptions.headers, responseType: 'blob' })
  }
  DownloadSystemDoc(data: SystemAttachDoc): Observable<Blob> {
    return this.http.post(BASEURL + 'api/contract/downloadSystemDoc/', data, { headers: httpOptions.headers, responseType: 'blob' })
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logA(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private logA(message: string) {
    console.log(message);
    // this.messageService.add('ScgCompanyService: ' + message);
  }

}

