// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  R3_GI: "https://qascbmpi.scg.com:44332/RESTAdapter/R3GIGRRefDN",
  S4_GI: "https://qascbmpi.scg.com:44332/RESTAdapter/GIGRRefDN",
  R3_GI_Reverse: "https://qascbmpi.scg.com:44332/RESTAdapter/R3REVGIGRRefDN",
  S4_GI_Reverse: "https://qascbmpi.scg.com:44332/RESTAdapter/REVGIGRRefDN",
  LOG: "https://localhost:44353/api/SCG",
  PDF: "https://localhost:44353/api/PdfCreator/PDF",
  DNGetList: "https://qascbmpi.scg.com:44332/RESTAdapter/DNGetList",
  R3DNGetList: "https://qascbmpi.scg.com:44332/RESTAdapter/R3DNGetList",
  DNGetGroup: "https://qascbmpi.scg.com:44332/RESTAdapter/DNGetGroup",
  ShowLogInDev: true,
};

export const ADFS = {
  production: false,
  issuer: "https://sts.windows.net/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4/",
  resource: "https://test-scgdistpurchasing.nexterdigitals-dev.com",
  redirectUri: "https://test-scgdistpurchasing.nexterdigitals-dev.com/login",
  loginUrl: "https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4/oauth2/authorize",
  tokenEndpoint: "https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4/oauth2/token",
  logoutUrl: "https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4/oauth2/logout",
  clientId: "2c19a509-bdf1-4ea8-be07-370a494e3363",
  scope: "openid profile email",
  responseType: "code",
  disablePKCE: true,
  oidc: false,
  code_challenge: 'q22bDS8NlkgNczKvj32Mkv1DWiFaa28BococCzrqICo',
  code_verifier: '7d3u0GVLYXhZ-XoxQzifgglAhpZhvWAYmQDkwunMXko',
};
